















































































































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import {
  apiDeviceCategoryList,
  apiDeviceCategoryAdd,
  apiDeviceCategoryEdit,
  apiDeviceCategoryDetail,
} from '@/api/imagetru3d'
@Component({
  components: {
    MaterialSelect,
  },
})
export default class DeviceTypeEdit extends Vue {
  $refs!: { form: any }
  id!: any
  loading = false
  hasPid = 0
  deviceCategoryList = []
  form = {
    name: '',
    pid: '',
    sort: '',
    status: 0,
  }

  rules = {
    name: [
      {
        required: true,
        message: '请输入类型名称',
        trigger: ['blur', 'change'],
      },
    ],
  }

  // 新增设备类型
  handleSave() {
    this.$refs.form.validate((valid: boolean) => {
      if (valid) {
        // 如果没有父级类型
        if (!this.hasPid) {
          // 就是 ''
          this.form.pid = '0'
        }
        // 如果排序没有填写，默认是0
        if (!this.form.sort) {
          this.form.sort = '0'
        }
        // 判断是编辑还是新增
        const api = this.id
          ? apiDeviceCategoryEdit(this.form)
          : apiDeviceCategoryAdd(this.form)
        // 新增或编辑后，返回上一界面
        api.then(() => {
          this.$router.go(-1)
        })
      } else {
        return false
      }
    })
  }

  // 类型详情
  getDeviceCategoryDetail() {
    this.loading = true
    apiDeviceCategoryDetail(this.id).then((res: any) => {
      // console.log(res)
      if (res.pid) {
        this.hasPid = 1
      }
      this.form = res
      this.loading = false
    })
  }

  // 获取设备类型列表
  getDeviceCategoryList() {
    apiDeviceCategoryList({ page_type: 1 }).then((res: any) => {
      res?.lists.forEach((item: any) => {
        item.sons &&
          item.sons.forEach((sitem: any) => {
            delete sitem.sons
          })
      })
      this.deviceCategoryList = res?.lists
    })
  }

  created() {
    this.id = this.$route.query.id
    this.id && this.getDeviceCategoryDetail()
    this.getDeviceCategoryList()
  }
}
